import React from 'react'
import { Link } from 'react-router-dom'

function Inicio() {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                    {/*<div className="carousel-item active">
                        <img className="d-block w-100" src="intro-bg.jpg" alt="Primeiro Slide"></img>
                        <div className="carousel-caption ">
                            <h1 id="fonteImagem">QUEM SOMOS </h1>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src="maquinas.png" alt="Segundo Slide"></img>
                        <div className="carousel-caption">
                            <h1 id="fonteImagem">Agrícola</h1>
                            <p id="fonteimagem1">TESTE TESTE TESTE</p>
                    </div>
                    </div>*/}

                    <div className="carousel-item active">
                        <img className="d-block w-100" src="inicio.png" alt="Terceiro Slide"></img>
                        <div className="carousel-caption">
                            
                            <h1 className="color_light fw_light"><Link to="/produtos" className="text-white">  NOSSOS PRODUTOS  </Link></h1>

                            
                        </div>
                    </div>

                    {/*<div className="carousel-item">
                        <img className="d-block w-100" src="acucar.png" alt="Terceiro Slide"></img>
                        <div className="carousel-caption">
                            <h1 id="fonteImagem">AÇUCAR</h1>
                            <p id="fonteimagem1"></p>
                        </div>
                </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Anterior</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Próximo</span>
                    </a>
                    */}
            </div>
        </div>
    )
}

export default Inicio