import React from 'react';
import styled from 'styled-components'

function Footer() {
    return (
        <FooterContainer className="main-footer">
            <div className="footer-middle ">
                <div className="container">
                    <div className="row">
                        {/* Colunas 1 */}
                        <div id="footerInfo" className="col-md-3 col-sm-6">
                            <img src="logo_uvapb.png" height="100px" className="align-left pb-2 pt-1"/>
                            <ul className="list-unstyled pt-1">
                                <li>Usina Vista Alegre</li>
                                <li>(15)3275-8400</li>
                                <li>Rod. Aristides da Costa Barros, Km 19</li>
                                <li>Bairro Pinhal - Itapetininga/SP</li>   
                                <li>18209-600</li>  
                            </ul>
                        </div>
                        {/* Colunas 2 */}
                        <div className="col-md-3 col-sm-6">
                            <h4>Mapa do site</h4>
                            <ul className="list-unstyled">
                                <li><a href="#">Inicio</a></li>
                                <li><a href="#">Institucional</a></li>
                                <li><a href="#">Produtos</a></li>
                                <li><a href="#">Fale conosco</a></li>
                                <li><a href="#">Trabalhe conosco</a></li>
                            </ul>
                        </div>
                        {/* Colunas 3 */}
                        <div className="col-md-3 col-sm-6">
                            <h4>Redes sociais</h4>
                            <ul className="list-unstyled" >
                                <li ><a href="#"><img className="pr-2" src="facebook.png" height="20px" ></img>Facebook</a></li>
                                <li ><a href="#"><img className="pr-2" src="linkedin.png" height="20px"/>LinkedIn</a></li>
                            </ul>
                            <h5 className="pt-3">Pouso de Helicópteros</h5>
                            <div className="footerInfo">
                            <ul className="list-unstyled pt-1">
                                <li>Latitude: 23º 29'20.64"S</li>
                                <li>Longitude: 48º 06'14.63"O</li>     
                            </ul>
                            </div>
                        </div>
                        {/* Colunas 4 */}
                        <div className="col-md-3 col-sm-6">
                            <img src="etanol.png" className="pt-3" height="100px"></img>
                                  
                          
                        </div>
                    </div>
                    {/*Footer Bottom*/}
                    <div className="footer-bottom">
                        <p classname="text xs-center">
                            &copy;{new Date().getFullYear()} Departamento de técnologia da Informação
                    </p>
                    </div>
                </div>
            </div>
        </FooterContainer>
    )
}

export default Footer

const FooterContainer = styled.footer`
.footer-middle{
    background: var(--mainDark);
    padding-top: 1rem;
    color: var(--mainWhite);
    padding-bottom: 1rem;
} 

.footer-bottom{
    padding-bottom: 1rem;
}

ul li a{
    color: var(--mainGrey);
    vertical-align: bottom;
}

ul li a:hover{
    color: var(--mainLigthGrey);
    vertical-align: bottom;
}


`;