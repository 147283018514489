import React from 'react'

function Contato() {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active ">
                    <img className="d-block w-100 h-100" src="produtos.png" alt="Terceiro Slide"></img>
                    <div className="carousel-caption ">
                        <h1 id="fonteImagem">Fale conosco</h1>
                    </div>
                </div>
            </div>

            <div class="pricing-header px-3 py-3 pt-md-4 pb-md-0 mx-auto text-center">
                <h3 className="h3">Entre em contato com a gente</h3>
            </div>
            <hr className="featurette-divider" />
            <div className="container clearfix">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 m_xs_bottom_30">
                        <div className="container-fluid">
                            <div className="map-responsive">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14636.652113013075!2d-48.1055236!3d-23.4906367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa0fce6f74d05e384!2sUsina%20Vista%20Alegre!5e0!3m2!1spt-BR!2sbr!4v1597317502054!5m2!1spt-BR!2sbr" frameborder="0" styles="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 m_xs_bottom_30">
                        <form action="http://formmail.kinghost.net/formmail.cgi" method="POST">
                            <input type="hidden" name="recipient" value="lucas.camargo7@fatec.sp.gov.br" />
                            <input type="hidden" name="redirect" value="http://localhost:3000/" />
                            <input type="hidden" name="email" value="lucas.pereira@vistalegre.ind.br"></input>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label for="inputNome">Nome</label>
                                    <input type="text" className="form-control" id="inputaNome" placeholder="Nome"></input>
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputEmail4">Email</label>
                                    <input type="email" className="form-control" name="replyto" id="inputEmail4" placeholder="Email"></input>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputAssunto">Assunto</label>
                                    <input type="text" class="form-control" name="subject" id="inputAssunto" placeholder="Assunto" />
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="setorContato">Aos cuidados do setor de:</label>
                                    <select className="form-control" id="setorContato">
                                        <option>Suprimentos</option>
                                        <option>Exportação</option>
                                        <option>Outros</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label for="areaMensagem">Mensagem</label>
                                <textarea class="form-control" id="areaMensagem" name="comentarios" rows="4"></textarea>
                            </div>

                            <button type="submit" class="btn btn-outline-success">Enviar</button>

                        </form>
                        <br></br>
                    </div>
                </div>
            </div>
            
        </div>


    )
}

export default Contato