import React from 'react'

function Institucional() {
    return (

        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active ">
                    <img className="d-block w-100 h-100" src="colaboradores.png" alt="Terceiro Slide"></img>
                    <div className="carousel-caption ">
                        <h1 id="fonteImagem">SOBRE NÓS</h1>

                    </div>
                </div>

            </div>
            <div class="pricing-header px-3 py-3 pt-md-4 pb-md-0 mx-auto text-center">
                <h3 className="h3">Nossa história</h3>
                <p class="mb-0 pt-2"><em>Fundada em 17/10/1980 pelo Comendador Manuel Rodrigues Tavares de Almeida a empresa <strong>Engenho de Aguardente Vista Alegre LTDA.</strong></em></p>
                <div className="container pt-2 align-center">
                    <div className="row">
                        <div className="col-md-12">
                            <img src="fotoantiga.gif" className="rounded float-left pt-3 pr-4 pb-2" />
                            <p className="text-justify pt-3">Com aquisição da fazenda Vista Alegre e do Engenho Vista Alegre e em 1982, a fazenda São João, unificou assim as duas grandes áreas de cana-de-açúcar da região.</p>
                            <p className="text-justify pt-1">Neste mesmo ano, fundou-se a Agrícola Almeida Ltda. Com investimentos em renovação e plantio de novas áreas de cana-de-açúcar, reformando, adaptando a unidade industrial e expandindo a produção de aguardente. </p>
                            <p className="text-justify pt-1">Em 1983, foi fundada a Agro Industrial Vista Alegre Ltda., seu novo parque fabril com capacidade inicial de moagem de 300 mil toneladas de cana-de-açúcar, dando inicio a produção de álcool hidratado carburante. </p>
                            <p className="text-justify pt-1">Em 2001, com uma produção de cana-de-açúcar estabilizada em 350 mil toneladas, surgiu a oportunidade de expansão agrícola e conseqüentemente a necessidade de expansão do parque industrial, traçando um novo cenário de expansão para até 800 Mil toneladas de cana-de-açúcar e assim, iniciar a produção de xarope e auto-suficiência em energia elétrica, fase que antecede a produção do açúcar. Com elevados investimentos a partir deste ano, inicia a produção de álcool anidro.  </p>
                            <p className="text-justify pt-1">Já em 2004 inicia também a produção do açúcar VHP. </p>
                            <p className="text-justify pt-1">A partir desse momento, o crescimento em função das oportunidades de mercado e da região, tornou possível criar um plano de investimento maciço na expansão das lavouras de cana-de-açúcar com projeção para a safra 2011 de 1.850.000 toneladas de cana de açúcar. </p>
                            <p className="text-justify pt-1">Em 2009 iniciou-se a cogeração de energia, com capacidade para 60 MWh. </p>
                            <p className="text-justify pt-1">Com a nova expansão agrícola, necessidade de ampliação do parque industrial e oportunidade de negócio com exportação de energia, a empresa decidiu continuar investindo neste segmento em um projeto de 2.500.000 toneladas.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container align-center">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="container h-100" id="missao">
                            <h2 className="pt-4 text-center">MISSÃO</h2>
                            <div className="container align-center ">
                                <p className="lead text-center"> "Produzir cana açúcar, etanol e energia de forma sustentável."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 " >
                        <div className="container h-100" id="visao">
                            <h2 className="pt-4 text-center">VISÃO</h2>
                            <div className="container align-center">
                                <p className="lead text-center">"Ser rentável, gerando desenvolvimento da empresa, dos colaboradores e da sociedade."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" >
                        <div className="container h-100" id="valores">
                            <h2 className="pt-4 text-center">VALORES</h2>
                            <div className="container align-center">
                                <ul className="list-unstyled mt-3 ml-3 mb-4 text-left pl-0">
                                    <li className="lead"><strong>E</strong>xcelência</li>
                                    <li className="lead"><strong>T</strong>ransparência</li>
                                    <li className="lead"><strong>I</strong>ntegridade</li>
                                    <li className="lead"><strong>C</strong>omrrometimento</li>
                                    <li className="lead"><strong>A</strong>uto desenvolvimento</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br></br>
        </div >


    )
}

export default Institucional
