import React from 'react'

function Produtos() {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active ">
                    <img className="d-block w-100 h-100" src="produtos.png" alt="Terceiro Slide"></img>
                    <div className="carousel-caption ">
                        <h1 id="fonteImagem">PRODUTOS</h1>

                    </div>
                </div>

            </div>
            <div class="pricing-header px-3 py-3 pt-md-4 pb-md-0 mx-auto text-center">
                <h3 className="h3">Conheça nossos produtos</h3>
            </div>
            <hr className="featurette-divider" />
            <div className="container marketing pt-4">
                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">AÇUCAR VHP <span class="text-muted"> - Very High Polarization</span></h2>
                        <p class="lead">Açúcar que pode ser utilizado no consumo. Possui uma cor que se diferencia do açúcar cristal branco, pois é produzido sem a utilização de enxofre e cal.</p>
                    </div>
                    <div className="col-md-5">
                        <img class="featurette-image img-fluid mx-auto" alt="prduto_acucar" src="produto_acucar.png" data-holder-rendered="true" />
                    </div>
                </div>
                <hr className="featurette-divider" />
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading">ETANOL AEAC <span class="text-muted"> - Etilico Anidro Carburante</span></h2>
                        <p class="lead">Utilizado em mistura com a gasolina. Capaz de reduzir a poluição.</p>
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img class="featurette-image img-fluid mx-auto" alt="produto_alcool" src="produto_alcool.png" data-holder-rendered="true" />
                    </div>
                </div>
                <hr className="featurette-divider" />
                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">ETANOL AEH<span class="text-muted"> - Etilico Hidratado</span></h2>
                        <p class="lead">Usado em bebibas e para fins industriais.</p>
                    </div>
                    <div className="col-md-5">
                        <img class="featurette-image img-fluid mx-auto" alt="prduto_acucar" src="produto_alcool1.jpg" data-holder-rendered="true" />
                    </div>
                </div>

                <hr className="featurette-divider" />
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading">ETANOL AEHC <span class="text-muted"> - Etilico Hidratado Carburante</span></h2>
                        <p class="lead">Produto renovável e limpo que contribui para redução do efeito estufa do planeta. Auxilia na diminuição da poluição do ar e minimiza impactos na saúde pública. O AEHC é utilizado em carros e possui água, cerca de 7%. Este alcoól não é utilizado como aditivo à gasolina.</p>
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img class="featurette-image img-fluid mx-auto" alt="produto_alcool" src="produto_alcool2.png" data-holder-rendered="true" />
                    </div>
                </div>
                <hr className="featurette-divider" />
                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">ENERGIA<span class="text-muted"> - Bioenergia</span></h2>
                        <p class="lead">A Usina Vista Alegre reutiliza o excedente de bagaço da cana-de-açúcar como fonte de geração de energia, agregando valor a esse resíduo da cadeia produtiva do açúcar e etanol. A capacidade instalada de geração de energia é de 60MWH, onde parte é utilizada nos processos produtivos e o excedente comercializado conforme a demanda de bagaço de cana e em conformidade com os órgãos reguladores.</p>
                    </div>
                    <div className="col-md-5">
                        <img class="featurette-image img-fluid mx-auto" alt="prduto_acucar" src="bioenergia.jpg" data-holder-rendered="true" />
                    </div>
                </div>
                <hr className="featurette-divider" />
            </div>
        </div>
    )
}

export default Produtos