import React, { useState } from 'react';
import axios from 'axios';

function App() {


  const [campos, setCampos] = useState({
    
    nome: '',
    email: '',
    assunto: '',
    mensagem: '',
    anexo: ''
  });

  function redirecionar(){
    if (document.f1.nome.value.length == 0){
        alert("Preencha o campo NOME corretamente");
        return 0
    }

    if (document.f1.email.value.length == 0){
        alert("Preencha o campo EMAIL corretamente");
        return 0
    }

    if (document.f1.assunto.value.length == 0){
        alert("Preencha o campo ASSUNTO corretamente");
        return 0
    }
    
    if (document.f1.mensagem.value.length == 0){
        alert("Preencha o campo MENSAGEM corretamente");
        return 0
    }
    
    alert("Formulário enviado com sucesso");
    //window.location.href = "http://vistalegre.ind.br/";


  }

  function handleInputChange(event) {
   
    if (event.target.name === "anexo")
    campos[event.target.name] = event.target.files[0];
            
    else
    campos[event.target.name] = event.target.value;     
    setCampos(campos);
    
  }

  function send() {
    const formData = new FormData();
    Object.keys(campos).forEach(key => formData.append(key, campos[key]));
    axios.post('https://email-uva-api.herokuapp.com/send',
    //axios.post('http://localhost:3030/send',
    formData,
      {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        }
      })
      .then(response => { console.log(response.data); })
  }

  function handleFormSubmit(event) {
    event.preventDefault();
   
    send(campos);
  }

  //campos.setor = document.getElementById('setor').options[document.getElementById('setor').selectedIndex].innerText;
  //console.log(campos);

  return (

    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active ">
          <img className="d-block w-100 h-100" src="produtos.png" alt="Terceiro Slide"></img>
          <div className="carousel-caption ">
            <h1 id="fonteImagem">Fale conosco</h1>
          </div>
        </div>
      </div>
      <div class="pricing-header px-3 py-3 pt-md-4 pb-md-0 mx-auto text-center">
        <h3 className="h3">Entre em contato com a gente</h3>
      </div>
      <hr className="featurette-divider" />
      <div className="container clearfix">

        <div className="row">

          <div className="col-lg-6 col-md-6 col-sm-6 m_xs_bottom_30">
            <div className="container-fluid">
              <div className="map-responsive">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14636.652113013075!2d-48.1055236!3d-23.4906367!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa0fce6f74d05e384!2sUsina%20Vista%20Alegre!5e0!3m2!1spt-BR!2sbr!4v1597317502054!5m2!1spt-BR!2sbr" frameborder="0" styles="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 m_xs_bottom_30">
            <div className="container">


              <form onSubmit={handleFormSubmit} name="f1">
              
                
                <div className="form-row">

                  <div className="form-group col-md-6">
                    <label htmlFor="nome">Nome</label>
                    <input className="form-control" type="text" id="nome" name="nome" placeholder="Nome.." onChange={handleInputChange} />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="email">E-mail</label>
                    <input className="form-control" type="text" id="email" name="email" placeholder="E-mail para contato.." onChange={handleInputChange} />
                  </div>

                  </div>

                  <div className="form-group">
                    <label htmlFor="nome">Assunto</label>
                    <input className="form-control" type="text" id="assunto" name="assunto" placeholder="Aos cuidados de..." onChange={handleInputChange} />
                  </div>


                <div class="form-group ">
                  <label htmlFor="mensagem">Mensagem</label>
                  <textarea id="mensagem" name="mensagem" placeholder="Digite sua mensagem" className="form-control" rows="4" onChange={handleInputChange}></textarea>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input className="btn btn-outline-secondary" type="file" id="anexo" name="anexo" onChange={handleInputChange} />

                  </div>
                </div>
                
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input class="btn btn-outline-success" type="submit" value="Enviar" onClick={redirecionar} />
                  </div>
                </div>

              </form>
              <hr className="featurette-divider" />
            </div>
          </div>

        </div>
      </div>
    
    </div >
   

  );
}

export default App;
