import React, { useState } from 'react'
import logo from './logo.svg';
import './App.css';
import Footer from './components/layouts/Footer';
import Navbar from './components/layouts/Navbar';
import Inicio from './components/pages/Inicio';
//Importando router - para navegar entre as paginas
import { Switch, Route } from 'react-router-dom';
import Institucional from './components/pages/Institucional';
import Contato from './components/pages/Contato';
import Erro from './components/pages/Erro';
import Produtos from './components/pages/Produtos';
import Contato1 from './components/pages/Contato1';
import Navbar2 from './components/layouts/Navbar2';
import axios from 'axios';


function App() {

  const [campos, setCampos] = useState({
    nome: '',
    email: '',
    mensagem: '',
    anexo: ''
});
function handleInputChange(event){
  if(event.target.name === "anexo")
    campos[event.target.name] = event.target.files[0];
  else
    campos[event.target.name] = event.target.value;
  setCampos(campos);
}

function send(){
  const formData = new FormData();
  Object.keys(campos).forEach(key => formData.append(key, campos[key]));
  axios.post('http://localhost:3030/send', 
            formData,
            {
              headers: {
               "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
              }
            })
    .then(response => { console.log(response.data); })
}

function handleFormSubmit(event){ 
  event.preventDefault(); 
  console.log(campos); 
  send(campos);
}

  return (
    <div>
      <Navbar />
      <Switch>
        <Route path="/contato" component={Contato}></Route>
        <Route exact path="/" component={Inicio}></Route>
        <Route path="/institucional" component={Institucional}></Route>
        <Route path="/produtos" component={Produtos}></Route>
        <Route path="/contato1" component={Contato1}></Route>
        <Route component={Erro}></Route>
      </Switch>
      <Footer />
      
    </div>

  );
}



export default App;
