import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <div className="container-fluid p-1 shadow-sm mb-1 bg-white rounded ">
      <nav className="navbar navbar-expand-md navbar-light bg-light bg-white">
        <Link className="navbar-brand" to="/">
          <img src="logo_uva.png" width="98,267716535px" height="67,653543307px" id="nav_logo" />
          <a id="brand">        Energia a favor da vida</a>
        </Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu"
          aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <section className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav" id="testeNav">
            <Link className="nav-item nav-link" to="/"><a className="p-2 text-dark">Inicio</a></Link>
            <Link className="nav-item nav-link" to="/institucional"><a className="p-2 text-dark">Institucional</a></Link>
            <Link className="nav-item nav-link" to="/produtos"><a className="p-2 text-dark">Produtos</a></Link>
            <Link className="nav-item nav-link" to="/contato"><a className="p-2 text-dark">Contato</a></Link>
          </ul>
        </section>

      </nav>

    </div>

  )

}

export default Navbar